<template>
    <div class="container" data-v-35cf7caa="">
        <div class="row" data-v-35cf7caa="">
            <div class="col-12" data-v-35cf7caa="">
                <div class="sign__content" style="min-height: 91vh;">
                    <form class="sign__form sign__trailer card" @submit.prevent="onLogin()">
                        <a class="sign__logo" style="margin-bottom: 10px;">
                            <img src="../../assets/zain_gray.png" style="margin-top: 15px;margin-bottom: 20px;">
                        </a>
                        <p style="color: #fff">رقم الهاتف </p>
                        <div class="sign__group" >
                            <input type="phone" class="sign__input input" v-model="msisdn" autocomplete="off" placeholder="رقم الهاتف" onfocus="this.placeholder = ''" onblur="this.placeholder = '09xxxxxxxx'" style="background-image: linear-gradient(0deg, #cbe7ea 0, #cbe7ea);border: 1px solid #19c6cb;border-radius: 8px;height: 44px;position: relative;color: #046162;margin-top: 38px !important;font-size: 20px;width: 100%;padding: 0 20px;text-align: center;margin: 16px;font-weight: 600;">
                        </div>
                        <center data-v-35cf7caa="">
                            <div class="sign__group" style="justify-content: center;" data-v-35cf7caa="">
                                <!--<span class="article__content" style="color: rgb(2, 96, 151); align-content: flex-end; font-family: &quot;Cairo&quot;, sans-serif;" data-v-35cf7caa="">إنضم الينا الان وتمتع بتجربة فريدة قم بالضغط علي زر "اشتراك جديد</span>-->
                                <!-- <a href="live.html" style="width: 200px;" class="sign__btn pointer header-btn" >تسجيل الدخول </a> -->
                                <button class="sign__btn button pointer header-btn">
                                    <span v-if="loading" class="px-1 ">جاري تسجيل الدخول</span> &nbsp;
                                    <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                    <span v-else class="arabickufi">تسجيل الدخول</span>
                                </button>
                            </div>
                        </center>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTPDSP } from '@/Api/http-dsp';
import { useToast } from "vue-toastification";
export default {
   data() {
        return {
           msisdn: "",
           loading: false,
        };
    },
    // mounted() {
    //    if (!this.$cookie.isCookieAvailable("msisdn") && !this.$cookie.isCookieAvailable("status")) {
    //       this.$router.push({ path: "/" })
    //    }
    // },
    methods: {
      async onLogin() {
         const toast = useToast();
          let input = document.querySelector(".input");
          let button = document.querySelector(".button");
          button.disabled = true;
          input.addEventListener("change", stateHandle);

          function stateHandle() {
            if(document.querySelector(".input").value === "") {
               button.disabled = true;
            } else {
               button.disabled = false;
            }
          }
          if (this.msisdn != "") {
               var phoneno = (/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/);
               if (this.msisdn.match(phoneno)) {
                  if (this.msisdn.startsWith("09")) {
                     let phone = this.msisdn.substr(1);
                     this.loading = true
                     await HTTPDSP.post("DSPCheckLogin.php?msisdn=249"+phone).then((res) => {
                            if (res.data.status == 1 && res.data.remming_minutes > 0) {
                                this.$cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('msisdn', res.data.msisdn, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes,})
                                toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", { timeout: 2000 });
                                setTimeout(() => this.$router.push({ path: "/" }), 2500);
                            } else if (res.data.status == 1 && res.data.remming_minutes < 0) {
                                this.$cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('msisdn', res.data.msisdn, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes,})
                                toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", { timeout: 2000 });
                            } else if (res.data.status == 0) {
                                toast.warning("عفوا انت لست مشترك في هذة المنصة", { timeout: 2000, });
                                window.open("https://dsplp.sd.zain.com/?p=9619984619", "_self");	
                                // setTimeout(() => this.$router.push({ path: "/Subscribe" }), 2500);
                            }else {
                                toast.info("عفوا انت لست مشترك في الخدمة " , { timeout: 2000 });
                                window.open("https://dsplp.sd.zain.com/?p=9619984619", "_self");	
                                // setTimeout(() => this.$router.push({ path: "/Subscribe" }), 2500);	
                            }
                        }).catch((error) => {
                           console.log(error);
                        }).finally(() => {
                           this.loading = false;
                        });
                  } else {
                     toast.info("رقم الهاتف ليس زين" , { timeout: 2000 });
                  }
               } else {
                  toast.info("رقم الهاتف يجب ان يتكون من 10 رقم ويبدا بي 09 " , { timeout: 2000 });
               }
            } else {
               toast.info("الرجال ادخال رقم الهاتف" , { timeout: 2000 });
            }
      }
    },
}
</script>

<style>

</style>